import React, { Component } from "react";
import Link from "next/link";
import { hostEventUrl } from "../config/config";
import Image from "next/future/image";
// import TwitterCard from "../components/TwitterCard";

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={`container-fluid footer px-0 ${this.props.className ? this.props.className : ""}`}>
                <div className="row m-0 p-0">
                    <div className="col-lg-3 d-lg-block text-center pt-5 row position-relative">
                        {/* <div className="offset-4 col-4">
                            <Link href="/">
                                <img
                                    loading="lazy"
                                    src="/img/logo-short-white.svg"
                                    className="w-100"
                                    alt="Konfhub Logo"
                                />
                            </Link>
                        </div> */}
                        <Image className="octopus_logo-footer mx-auto" alt="mascot_kh" src="/logos/konfhub-octopus.png" width={100} height={100} />
                    </div>
                    <div className="col-lg-9 col-md-12 col-xs-12 footer-links pt-5 row text-md-left text-center mx-auto">
                        <div className="col-lg-8 col-xs-12 row">
                            <div className="col-lg-6 col-xs-12 text-md-start d-flex justify-content-center">
                                {/* <div className="links-title mb-2">More on Konfhub</div> */}
                                <div className="links">
                                    <a className="my-2 text-lg-start text-center" href={`${hostEventUrl}?host_event=true`}>
                                        Hosting
                                    </a>
                                    <Link href="/pricing">
                                        <a className="my-2 text-lg-start text-center">
                                            Pricing
                                        </a>
                                    </Link>
                                    <a
                                        className="my-2 text-lg-start text-center"
                                        href="mailto:reachus@konfhub.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Contact Us
                                    </a>
                                    <Link href="/logos">
                                        <a className="my-2 text-lg-start text-center">
                                            Brand Assets
                                    </a>
                                    </Link>
                                    {/* <Link href="https://konfhub.medium.com/">
										<a className="my-2" target="_blank">
                      Blog
										</a>
									</Link> */}
                                    {/* <Link href="https://s3.amazonaws.com/konfhub.com/KonfHub-Sept-Newsletter.pdf">
										<a className="my-2" target="_blank">
                      Newsletter
										</a>
									</Link> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-xs-12 text-md-start d-flex justify-content-center">
                                {/* <div className="links-title mb-2">Quick Links</div> */}
                                <div className="links">
                                    {/* <Link href="/code-of-conduct">
										<a className="my-2 text-lg-start text-center">
                      Code of Conduct
										</a>
									</Link>
									<Link href="/terms-and-conditions">
										<a className="my-2 text-lg-start text-center">
                      Terms &amp; Conditions
										</a>
									</Link>
									<Link href="/privacy-policy">
										<a className="my-2 text-lg-start text-center">
                      Privacy Policy
										</a>
									</Link> */}
                                    <a
                                        href="https://quiz.konfhub.com/"
                                        target="_blank"
                                        className="my-2 text-lg-start text-center"
                                    >
                                        QuizHub
                                    </a>
                                    <Link href="/communities">
                                        <a className="my-2 text-lg-start text-center">
                                            Community
                                        </a>
                                    </Link>
                                    {/* <a
                                        href="https://konfhub.freshteam.com/jobs"
                                        target="_blank"
                                        className="my-2 text-lg-start text-center"
                                    >
                                        Careers
                                    </a> */}
                                    <Link href="/awards">
                                        <a className="my-2 text-lg-start text-center">
                                            Awards
                                        </a>
                                    </Link>
                                    {/* <Link href="/about-us">
										<a className="my-2">About Us</a>
									</Link> */}
                                    {/* <Link href="/contact-us"> */}

                                    {/* </Link> */}
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12 center_aliging mt-md-5 mb-5">
                                <div className="row  mb-md-0 mb-3">
                                    <div className="col-12">
                                        <div className="social-links">
                                            <a
                                                href="https://www.facebook.com/konfhub/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="me-2"
                                            >
                                                <i className="fab fa-facebook-square"></i>
                                            </a>
                                            <a
                                                href="https://twitter.com/konfhub"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="mx-2"
                                            >
                                                <img className="position-relative" Style="top:-5px; height:40px ; width:36px;" src="/home/twitter-logo-white.svg" alt="twitter icon" />
                                            </a>
                                            <a
                                                href="https://www.linkedin.com/in/konfhub/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="mx-2"
                                            >
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                            <a
                                                href="https://www.instagram.com/konfhub_tech_conference/"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="mx-2"
                                            >
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                            <a
                                                href=" https://konf.me/discord-web"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="mx-2"
                                            >
                                                <i className="fab fa-discord"></i>
                                            </a>
                                            {/* discord option to be added later with the correct link */}
                                            {/* <a
                                                href="https://www.youtube.com/c/konfhubtech"
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <img
                                                    loading="lazy"
                                                    src="/img/discord.svg"
                                                    className="discord-logo mb-2"
                                                    alt="Discord Logo"
                                                />
                                            </a> */}
                                            <a
                                                href="https://www.youtube.com/c/konfhubtech"
                                                rel="noreferrer"
                                                target="_blank"
                                                className="mx-2"
                                            >
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center policies">
                                        <Link href="/code-of-conduct">
                                            <a className="my-2 text-lg-start text-center">
                                                Code of Conduct
                                            </a>
                                        </Link>{" "}
                                        |{" "}
                                        <Link href="/terms-and-conditions">
                                            <a className="my-2 text-lg-start text-center">
                                                Terms &amp; Conditions
                                            </a>
                                        </Link>{" "}
                                        |{" "}
                                        <Link href="/privacy-policy">
                                            <a className="my-2 text-lg-start text-center">
                                                Privacy Policy
                                            </a>
                                        </Link>{" "}
                                        |{" "}
                                        <Link href="/cookie-policy">
                                            <a className="my-2 text-lg-start text-center">
                                                Cookie Policy
                                            </a>
                                        </Link>
                                        {" "}|{" "}
                                        <Link href="/cancellation-refund-policy">
                                            <a className="my-2 text-lg-start text-center">
                                                Cancellation & Refund Policy
                                            </a>
                                        </Link>
                                        {" "}|{" "}
                                        <Link href="/responsible-disclosure">
                                            <a className="my-2 text-lg-start text-center">
                                                Responsible Disclosure
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="col-12 text-center">
                                        &copy; KonfHub Technologies LLP | 2019-
                                        {new Date().getFullYear()}. All Rights
                                        Reserved
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mx-auto mb-5">
                            {/* <TwitterCard
								content={`"Function Apps with Azure" by @vishwasnarayan5 is already started and here is the agenda Join in for the wonderful session!! LIVE URL: https://www.youtube.com/watch?v=62qhNhH9oCs …#AZDevIndia #Azure #msftdevdays`}
								likes="336"
								time="3hrs ago"
							/> */}
                            <div className="w-100 d-flex flex-column px-5 align-items-center gpdr-align pt-4">
                                <span className="mx-4 mb-3 text-lg-start text-center text-light">We are compliant with</span>
                                <Image
                                    // loading="lazy"
                                    src="/img/gpdr-logo.png"
                                    className="octopus_logo-footer mx-4"
                                    alt="Konfhub Logo"
                                    width={100}
                                    height={100}
                                    alt="KonfHub is GDPR compliant for data privacy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx>{`
                    .footer {
                        background-color: #260e20;
                    }

                    .footer .footer-links {
                        background-color: #260e20;
                        color: #fff;
                        font-family: "Nunito", sans-serif;
                    }

                    .footer .footer-links .links-title {
                        font-weight: 800;
                        font-size: 20px;
                    }

                    .footer .footer-links .links a {
                        text-decoration: none;
                        color: #fff;
                        display: block;
                    }

                    .footer .footer-links .links a:hover {
                        text-decoration: none;
                        color: #FB5850;
                    }


                    .footer .footer-links a:hover {
						text-decoration: none;
						color: #FB5850;
                    }
					.policies a {
						text-decoration: none;
						color: #fff;
					}

					.policies a:hover {
						text-decoration: none;
						color: #00d1db;
					}

                    .footer .footer-links .social-links a {
                        display: inline-block;
                        color: #fff;
                        font-size: 40px;
                    }
                    .footer .footer-links .copyright {
                        font-size: 14px;
                    }

                    .center_aliging {
                        align-items: center;
                        justify-content: center;
                        display: flex;
                    }

                    .logo-size {
                        height: 100px;
                        width: auto;
                    }

                    .discord-logo{
                        height: 40px;
                        width: 46px;
                        margin: 0;
                        padding: 0;
                        color: #fff;
                    }

                    .gpdr-align {
                        position: relative;
                        bottom: 40px;
                    }
                `}</style>
                <style jsx global>{`
                    .octopus_logo-footer {
                        width: auto !important;
                    }
                `}</style>
            </div>
        );
    }
}

export default Footer;
